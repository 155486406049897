import React from "react";
import { Badge } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

type Props = {
  isClassic?: boolean;
}

export const LeadTypeBadge: React.FC<Props> = ({
  isClassic,
}) => {
  const [t] = useTranslation();

  const styles = {
    borderColor: isClassic ? "gray.250" : "primary.100",
    bgColor: isClassic ? "white" : "primary.10",
  };

  return (
    <Badge
      borderWidth={1}
      borderRadius={4}
      fontSize={14}
      fontFamily="regular"
      textTransform="capitalize"
      fontWeight="normal"
      lineHeight="1"
      p={1}
      {...styles}
    >
      {t(`common.${isClassic ? "classic_lead" : "bto"}`)}
    </Badge>
  );
};
