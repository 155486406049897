import { EditCalculatorOfferSchema } from "settings/yup/schemas/editCalculatorOfferSchema";
import { capitalize } from "utils/capitalize";
import { getDeliveryPricesDefaultValues } from "utils/getDeliveryPricesDefaultValues";
import { CalculatorOfferRow } from "views/CalculatorOffers/types";

const parseValue = (value?: string): string => {
  if (!value) {
    return "";
  }

  return String(parseFloat(value))?.replace(".", ",");
};

export const getDefaultValues = (
  data?: CalculatorOfferRow,
): EditCalculatorOfferSchema | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    companyName: data?.dealer?.companyName || "",
    customerType: data?.customerType ? capitalize(data?.customerType) : "",
    listPriceDiscountRate: parseValue(data?.dealerDiscount?.listPriceDiscountRate),
    baseCommission: parseValue(data?.dealerDiscount?.baseCommission),
    commissionRate: parseValue(data?.dealerDiscount?.commissionRate),
    degradationFactor: parseValue(data?.dealerDiscount?.degradationFactor),
    degradationFactorMax: parseValue(data?.dealerDiscount?.degradationFactorMax),
    deliveryPrices: getDeliveryPricesDefaultValues([data?.deliveryPrice]),
    cplPlus: !!data?.dealerDiscount?.cplPlus,
  };
};
