import i18n from "translations/i18n";
import { DeliveryPrice } from "generated/graphql";
import yup from "settings/yup";

import maxValueTest from "../tests/maxValueTest";

const requiredFieldError = i18n.t("yup.mixed_required_field");

export const editCalculatorOfferSchema = yup.object().shape({
  companyName: yup.string(),
  customerType: yup.string(),
  listPriceDiscountRate: yup.string().test(maxValueTest())
    .required(requiredFieldError),
  baseCommission: yup.string().nullable()
    .required(requiredFieldError),
  commissionRate: yup.string().test(maxValueTest())
    .required(requiredFieldError),
  degradationFactor: yup.string().test(maxValueTest())
    .required(requiredFieldError),
  degradationFactorMax: yup.string().test(maxValueTest())
    .required(requiredFieldError),
  cplPlus: yup.boolean().nullable(),
});

export type EditCalculatorOfferSchema = {
  companyName: string;
  customerType: string;
  listPriceDiscountRate: string;
  baseCommission: string;
  commissionRate: string;
  degradationFactor: string;
  degradationFactorMax: string;
  deliveryPrices?: Pick<DeliveryPrice, "dealerPickupPrice" | "factoryPickupPrice" | "houseDeliveryPrice">[];
  cplPlus?: boolean;
};
